import React, { useEffect } from "react"
import { Helmet } from "react-helmet"
import { useLocation, useNavigate } from "react-router"
import DrawerMenu from "../../components/DrawerMenu"
import { useApp } from "../../components/app"
import { useAuth } from "../../components/app/Auth"
import { ReactComponent as ArrowForward } from "../../icons/white-arrow-forward.svg"
import notFoundImg from "../../images/page-404.png"
import "./index.less"

const Page404 = () => {
  const { user } = useAuth()
  const {
    app: { userType },
  } = useApp()
  const urlParams = new URLSearchParams(window.location.search)
  const loginToken = urlParams.get("loginToken")
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const handleClick = () => {
    window.location.href = window.location.origin + "/login"
  }
  useEffect(() => {
    if (pathname == "/login" && user) {
      navigate("/account/homepage")
    }
    if (pathname == "/") {
      if (user) {
        navigate("account/homepage")
      } else {
        navigate("/login")
      }
    }
  }, [])
  return (
    <React.Fragment>
      {!loginToken && (
        <React.Fragment>
          <Helmet>
            <title>KEVIN.MURPHY Tournament</title>
          </Helmet>
          {user && <DrawerMenu isLogo={userType == "salon"} />}
          <section className={"page-404"}>
            <div className={"image-wrapper"}>
              <img src={notFoundImg} alt={"media"} />
              <h1>The page you're looking for can't be found.</h1>
              <h2>Don't worry, let's get you back on track. Click the button bellow to try again.</h2>
              <div className={"button-wrapper"}>
                <button onClick={handleClick}>
                  {user ? "Back to Dashboard" : "Back to Login page"}
                  <ArrowForward />
                </button>
              </div>
            </div>
          </section>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

export default Page404
