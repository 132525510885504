import { Layout as BaseLayout } from "antd"
import { FC, Suspense, useEffect, useState } from "react"
import { Outlet } from "react-router-dom"
import NoConnection from "src/pages/NoConnection"
import DesktopNavbar from "../DesktopNavbar"
import { useApp } from "../app"
import { useAuth } from "../app/Auth"
import { TourProvider } from "../app/Tour"

const Layout: FC = () => {
  const [isOffline, setIsOffline] = useState(false)
  const { user } = useAuth()
  const {
    app: { userType },
  } = useApp()

  const handleOfflineEvent = () => {
    setTimeout(() => {
      !navigator.onLine && setIsOffline(true)
    }, 3000)
  }

  const handleRecheckConnection = () => {
    navigator.onLine && setIsOffline(false)
  }

  useEffect(() => {
    window.addEventListener("offline", handleOfflineEvent)
    return () => {
      window.removeEventListener("offline", handleOfflineEvent)
    }
  }, [])

  return (
    <TourProvider>
      <BaseLayout>
        <DesktopNavbar />
        <Suspense>
          {!isOffline ? (
            <Outlet />
          ) : (
            <NoConnection user={user} userType={userType} handleRecheckConnection={handleRecheckConnection} />
          )}
        </Suspense>
      </BaseLayout>
    </TourProvider>
  )
}

export { Layout }
