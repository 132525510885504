import { Tooltip, TooltipProps } from "antd"
import React, { FC, ReactNode } from "react"

import { useApp } from "src/components/app"

interface ToolTipInterface {
  sizeType?: "Full" | "Min"
  sizeSales?: number
  type:
    | "chart"
    | "verification"
    | "points"
    | "salonNumbers"
    | "totalPoints"
    | "navbar"
    | "disabledVerification"
    | "verifyVisible"
    | "searchAndFiltersLeaderboard"
    | "searchAndFiltersLeaderboardSalonSize"
  children: ReactNode
  verifiedBy?: string
  verifiedDate?: string | null
  verifiedTime?: string | null
  direction?: TooltipProps["placement"]
  linkName?: string
  disabled?: boolean
}

const ToolTipWrapper: FC<ToolTipInterface> = ({
  sizeType,
  sizeSales,
  children,
  type,
  verifiedBy,
  verifiedDate,
  verifiedTime,
  direction = "left",
  linkName,
  disabled = false,
}) => {
  const {
    app: { userName },
  } = useApp()

  const title = (
    <div style={{ fontWeight: "300" }}>
      {
        {
          chart: (
            <React.Fragment>
              {sizeType} size - <span style={{ color: "#32D74B" }}>{sizeSales}</span>
            </React.Fragment>
          ),
          verification: (
            <React.Fragment>
              Verified by {verifiedBy !== userName ? verifiedBy : userName}
              <br />
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span style={{ color: "#FFF", opacity: 0.35 }}>{verifiedDate}</span>
              </div>
              <span style={{ color: "#FFF", opacity: 0.35 }}>{verifiedTime}</span>
            </React.Fragment>
          ),
          points: <span>Enter the quantity sold of either full or mini sizes</span>,
          salonNumbers: <span>Total number of Salons signed up for the tournament </span>,
          totalPoints: (
            <span>
              <span style={{ color: "#32D74B" }}>Verified</span> and Unverified
            </span>
          ),
          navbar: <span>{linkName}</span>,
          disabledVerification: (
            <span>
              Verification is disabled,
              <span style={{ color: "#32D74B" }}> Other levels</span> must verify
            </span>
          ),
          verifyVisible: (
            <span>
              Verify all salons currently <br /> visible on this page.
            </span>
          ),
          searchAndFiltersLeaderboard: (
            <span>
              <span style={{ color: "#32D74B" }}>Search and Filter</span> by Distributor or Country to refine your
              results
            </span>
          ),
          searchAndFiltersLeaderboardSalonSize: (
            <span>
              <span style={{ color: "#32D74B" }}>Only registered stylists</span> are counted when determining salon size
            </span>
          ),
        }[type]
      }
    </div>
  )

  if (disabled) {
    return <React.Fragment>{children}</React.Fragment>
  }

  return (
    <Tooltip title={title} placement={direction}>
      {children}
    </Tooltip>
  )
}
export default ToolTipWrapper
