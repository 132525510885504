import { Tour, TourProps, TourStepProps } from "antd"
import React, { FC, PropsWithChildren, createContext, useContext, useEffect, useRef, useState } from "react"
import { useLocation, useNavigate } from "react-router"
import { useAuth } from "./Auth"

import { useBreakpoints } from "src/hooks/useBreakpoints"
import { ReactComponent as CloseIcon } from "src/icons/close.svg"

type RefUnionType =
  | "results"
  | "sales"
  | "addProducts"
  | "weeklySales"
  | "closeWeeklySales"
  | "leaderboard"
  | "filters"
  | "team"
  | "howItWorks"
  | "verify"
  | "salonSearch"
  | "report"

type TourContextType = {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  currentStep: number
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>
  refs: Record<RefUnionType, React.MutableRefObject<null>>
}

type TourProviderProps = PropsWithChildren<Partial<TourContextType>>

type UserRoleType = "salon" | "dsc" | "sales" | "distributor"

const getRoleName = (role: string | undefined): UserRoleType | undefined => {
  switch (role) {
    case "salon":
      return "salon"
    case "DSC":
      return "dsc"
    case "Sales Manager":
      return "sales"
    case "Distributor":
      return "distributor"
    case "Region":
      return "distributor"
    default:
      return
  }
}

export const TourContext = createContext<TourContextType | undefined>(undefined)

export function useTour(): TourContextType {
  const context = useContext(TourContext)

  if (context === undefined) {
    throw new Error("useTour must be used within an TourProvider")
  }

  return context
}

//#Tasks
// TODO: all role steps
// TODO: refactor with different steps on page

// type PageType = "leaderboard" | "homepage" | "team" | "report"

// DEPENDENS:
// 1. Role
// 2. Mobile
// 3. Page

// type NewStepsType = Record<
//   UserRoleType,
//   Record<"mobile" | "desktop", Record<PageType, { steps: TourStepProps; gap: TourProps["gap"] }[]>>
// >

// steps[role][display][page][currentStep]
//#endregion

export const TourProvider: FC<TourProviderProps> = ({ children }) => {
  const { user } = useAuth()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { isMobile } = useBreakpoints()
  // const [currentSteps, setCurrentSteps] = useState<TourStepProps[]>()

  const role = getRoleName(user?.role?.data?.attributes?.name)

  const [open, setOpen] = useState<boolean>(false)
  const [currentStep, setCurrentStep] = useState(0)

  const resultsRef = useRef(null)
  const salesRef = useRef(null)
  const addProductsRef = useRef(null)
  const weeklySalesRef = useRef(null)
  const closeWeeklySalesRef = useRef(null)
  const leaderboardRef = useRef(null)
  const filtersRef = useRef(null)
  const teamRef = useRef(null)
  const howItWorksRef = useRef(null)
  const verifyRef = useRef(null)
  const salonSearchRef = useRef(null)
  const reportRef = useRef(null)

  const refs: TourContextType["refs"] = {
    results: resultsRef,
    sales: salesRef,
    addProducts: addProductsRef,
    weeklySales: weeklySalesRef,
    closeWeeklySales: closeWeeklySalesRef,
    leaderboard: leaderboardRef,
    filters: filtersRef,
    team: teamRef,
    howItWorks: howItWorksRef,
    verify: verifyRef,
    salonSearch: salonSearchRef,
    report: reportRef,
  }

  const prevButtonDefault = { children: isMobile ? "<" : "Back" }

  const commonSteps: TourStepProps[] = [
    {
      title: "Results Comparison",
      description:
        "View a graph comparing this year's results with last year's. If there was no participation last year, only the current data will be shown.",
      target: () => refs.results.current,
      prevButtonProps: {
        ...prevButtonDefault,
      },
    },
    {
      title: "Weekly Sales Breakdown",
      description:
        "See the total products sold this week, compared to last week, and a breakdown by Full-size and Mini-size products.",
      target: () => refs.sales.current,
      prevButtonProps: {
        ...prevButtonDefault,
      },
    },
  ]

  // const asd: NewStepsType = {
  //   salon: {
  //     desktop: {
  //       homepage: [
  //         {
  //           steps: {
  //             title: "Results Comparison",
  //             description:
  //               "View a graph comparing this year's results with last year's. If there was no participation last year, only the current data will be shown.",
  //             target: () => refs.results.current,
  //             prevButtonProps: {
  //               ...prevButtonDefault,
  //             },
  //           },
  //           gap: { offset: 1 },
  //         },
  //       ],
  //     },
  //     mobile: {
  //       homepage: [
  //         {
  //           steps: {
  //             title: "Results Comparison",
  //             description:
  //               "View a graph comparing this year's results with last year's. If there was no participation last year, only the current data will be shown.",
  //             target: () => refs.results.current,
  //             prevButtonProps: {
  //               ...prevButtonDefault,
  //             },
  //           },
  //           gap: { offset: 1 },
  //         },
  //       ],
  //     },
  //   },
  // }

  // separate steps by location

  const steps: Record<UserRoleType, TourStepProps[]> = {
    salon: [
      ...commonSteps,
      {
        title: "Add Products",
        description:
          'Click "Add products" to enter sales for each stylist. Use "Unregistered Stylist" for non-participating stylists.',
        target: () => refs.addProducts.current,
        prevButtonProps: {
          ...prevButtonDefault,
        },
        nextButtonProps: {
          onClick: () =>
            (refs.addProducts.current as unknown as React.MutableRefObject<HTMLButtonElement>["current"]).click(),
        },
      },
      {
        title: "Enter Weekly Sales",
        description: (
          <React.Fragment>
            {"Choose the week from the dropdown. Next, enter the quanity of sold products per each stylist."}
            <br />
            <span>Tip: </span>
            {
              "You can edit the number of products sold by returning to this window and choosing the right week and stylist."
            }
          </React.Fragment>
        ),
        prevButtonProps: {
          ...prevButtonDefault,
          onClick: () =>
            (refs.closeWeeklySales.current as unknown as React.MutableRefObject<HTMLButtonElement>["current"]).click(),
        },
        nextButtonProps: {
          onClick: () => {
            setOpen(false)
            navigate("/account/leaderboard")
          },
        },
        target: () => refs.weeklySales.current,
      },
      {
        title: "Leaderboards",
        description:
          "The Leaderboards display the results for the 2024 Tournament, with three tabs available: Individual Stylist, salon Total, and salon Average.",
        prevButtonProps: {
          ...prevButtonDefault,
          onClick: () => {
            setOpen(false)
            navigate("/account/homepage")
          },
        },
        nextButtonProps: isMobile
          ? {
              onClick: () => {
                setOpen(false)
                navigate("/account/profile/team")
              },
            }
          : undefined,
        target: () => refs.leaderboard.current,
      },
      {
        title: "Filtering Options",
        description:
          "You can filter the table by typing in the Distributor or Country field, or by selecting a Salon Size from the dropdown list. Choose specific options from the dropdowns to refine your search.",
        prevButtonProps: {
          ...prevButtonDefault,
        },
        nextButtonProps: {
          onClick: () => {
            setOpen(false)
            navigate("/account/profile/team")
          },
        },
        target: () => refs.filters.current,
      },
      {
        title: "Profile > Team",
        description: (
          <React.Fragment>
            {"Add or remove stylists under the Team section."}
            <br />
            <span>Note: </span>
            {
              "If you remove stylists during a competition, their points will be removed from the stylist leaderboard but will be counted in your salon's leaderboard."
            }
          </React.Fragment>
        ),
        prevButtonProps: {
          ...prevButtonDefault,
          onClick: () => {
            setOpen(false)
            navigate("/account/leaderboard")
          },
        },
        nextButtonProps: {
          onClick: () => {
            setOpen(false)
            navigate("/account/how-it-works/about")
          },
        },
        target: () => refs.team.current,
      },
      {
        title: "How It Works",
        description:
          'Learn about the KM Tournament, its categories, prizes, and deadlines. To replay the guided tour, click "Start guided tour" on the About page.',
        prevButtonProps: {
          ...prevButtonDefault,
          onClick: () => {
            setOpen(false)
            navigate("/account/profile/team")
          },
        },
        target: () => refs.howItWorks.current,
      },
    ],
    dsc: [...commonSteps],
    sales: [
      ...commonSteps,
      {
        title: "Add Products",
        description:
          'Click "Add products" to enter sales for each stylist. Use "Unregistered Stylist" for non-participating stylists.',
        target: () => refs.addProducts.current,
        prevButtonProps: {
          ...prevButtonDefault,
        },
      },
      {
        title: "Verify Points",
        description:
          "Toggle to verify points for products. You can click the plus icon to expand Salons and see individual Stylists to verify them separately.",
        prevButtonProps: {
          ...prevButtonDefault,
        },
        target: () => refs.verify.current,
      },
      {
        title: "Salon Search",
        description:
          "You can find the right salon by clicking the 'Search' button. Enter the salon name or keywords, and the list will be filtered to show only matching options. After that, you will be able to verify the points.",
        prevButtonProps: {
          ...prevButtonDefault,
        },
        nextButtonProps: {
          onClick: () => {
            setOpen(false)
            navigate("/account/leaderboard")
          },
        },
        target: () => refs.salonSearch.current,
      },
      {
        title: "Leaderboards",
        description:
          "The Leaderboards display the results for the 2024 Tournament, with three tabs available: Individual Stylist, salon Total, and salon Average.",
        prevButtonProps: {
          ...prevButtonDefault,
          onClick: () => {
            setOpen(false)
            navigate("/account/homepage")
          },
        },
        target: () => refs.leaderboard.current,
      },
      {
        title: "Filtering Options",
        description:
          "You can filter the table by typing in the Distributor or Country field, or by selecting a Salon Size from the dropdown list. Choose specific options from the dropdowns to refine your search.",
        prevButtonProps: {
          ...prevButtonDefault,
        },
        nextButtonProps: {
          onClick: () => {
            setOpen(false)
            navigate("/account/report")
          },
        },
        target: () => refs.filters.current,
      },
      {
        title: "Report",
        description:
          'Here, you can filter points by team roles and  "Verified Only" points. Select weeks from the dropdown menu, and download the report as a CSV file.',
        prevButtonProps: {
          ...prevButtonDefault,
          onClick: () => {
            setOpen(false)
            navigate("/account/leaderboard")
          },
        },
        nextButtonProps: {
          onClick: () => {
            setOpen(false)
            navigate("/account/how-it-works/about")
          },
        },
        target: () => refs.report.current,
      },
      {
        title: "How It Works",
        description:
          'Learn about the KM Tournament, its categories, prizes, and deadlines. To replay the guided tour, click "Start guided tour" on the About page.',
        prevButtonProps: {
          ...prevButtonDefault,
          onClick: () => {
            setOpen(false)
            navigate("/account/report")
          },
        },
        target: () => refs.howItWorks.current,
      },
    ],
    distributor: [...commonSteps],
  }

  const mobileSteps: Record<UserRoleType, TourStepProps[]> = {
    ...steps,
    salon: steps.salon.filter(step => step.title !== "Filtering Options"),
  }

  const commonGaps = [24, 24]

  const gaps: Record<UserRoleType, (number | [number, number])[]> = {
    salon: [...commonGaps, 2, [4, 8], 24, 4, [24, 12], 12],
    dsc: [...commonGaps],
    sales: [...commonGaps, 2, [24, 8], 8, 12, 12, [0, 4]],
    distributor: [...commonGaps],
  }

  const commonGapsMobile: (number | [number, number])[] = [[0, 24], 12]

  const mobileGaps: Record<UserRoleType, (number | [number, number])[]> = {
    salon: [...commonGapsMobile, 2, 4, [48, 12], [48, 12], 96],
    dsc: [...commonGapsMobile],
    sales: [...commonGapsMobile, 2, [24, 4], 8],
    distributor: [...commonGapsMobile],
  }

  const getTourProps = (): TourProps => {
    const tourProps: TourProps = { steps: undefined, gap: { offset: 4 } }

    if (!role) {
      return tourProps
    }

    if (isMobile) {
      tourProps.steps = mobileSteps[role]
      tourProps.gap = { offset: mobileGaps[role][currentStep] }
    } else {
      tourProps.steps = steps[role]
      tourProps.gap = { offset: gaps[role][currentStep] }
    }

    return tourProps
  }

  const tourStepsProps = getTourProps()

  useEffect(() => {
    // start tour
    if (role && !localStorage.getItem("guided")) {
      if (currentStep === 0) {
        navigate("/account/homepage")
      }

      setOpen(true)
    }
  }, [currentStep, navigate, role])

  // useEffect(() => {
  //   // get back to step 3
  //   if (refs.addProducts.current && currentStep === 3) {
  //     const addProductsBtn = refs.addProducts.current as unknown as React.MutableRefObject<HTMLButtonElement>["current"]
  //     addProductsBtn.click()
  //     setOpen(true)
  //   }
  // }, [currentStep, refs.addProducts, refs.weeklySales])

  return (
    <TourContext.Provider
      value={{
        open,
        setOpen,
        currentStep,
        setCurrentStep,
        refs,
      }}
    >
      <Tour
        {...tourStepsProps}
        closeIcon={<CloseIcon />}
        open={open}
        onClose={() => {
          navigate("/account/homepage")
          setOpen(false)
          localStorage.setItem("guided", "true")
        }}
        onFinish={() => {
          navigate("/account/homepage")
          localStorage.setItem("guided", "true")
        }}
        current={currentStep}
        onChange={step => setCurrentStep(step)}
        indicatorsRender={(current, total) => (
          <span>
            {current + 1} of {total}
          </span>
        )}
        rootClassName={"guided-tour"}
      />
      {children}
    </TourContext.Provider>
  )
}
